<template> 
    <div >
        <div v-if="v_headFlag">
            <div class="ms-login">
                <div class="ms-title">
                    <a @click="imgRoute"><img class="logo left" src="@/assets/title.png" /></a>
                    <span > | {{fldObj['a_member']}} </span>
                </div>
            </div>
            <div style="border-bottom:3px solid #2d8cf0;margin-top:30px;margin-bottom:100px;"></div>
        </div>
        <div class="rowstyle">
            <Tabs value="account" style="width:520px;height:100%;margin-top:15px;">
                <TabPane :label="fldObj['a_tab1']" name="account" icon="md-contacts">     
                    <div   ref="login"   class="ms-content">
                        <div style="  display:flex;justify-content:between ">    
                            <el-input v-model="form.username" :placeholder="fldObj['a_username']" autofocus=true >                      
                                <template slot ="prepend" ><i class="el-icon-user" style="color:#409EFF;font-size:18px"></i></template>
                            </el-input>
                        </div>
                        <div style=" margin-top:20px;display:flex;justify-content:between ">             
                            <el-input
                                show-password
                                :placeholder="fldObj['a_pwd']"
                                autofocus=true
                                ref="pwd"
                                v-model="form.password"
                            >
                                <template slot ="prepend" ><i class="el-icon-lock" style="color:#409EFF;font-size:18px"></i></template>
                            </el-input>
                        </div> 
                        <div style=" margin-top:20px;display:flex;justify-content:between ">
                            <el-input
                                :placeholder="fldObj['a_valid']"
                                ref="holder"
                                style="width:72%"
                                v-model="validateCompute"
                            >
                                <template slot ="prepend" ><i class="el-icon-s-check" style="color:#409EFF;font-size:18px"></i></template>
                            </el-input>
                            <span style="margin-left:3px;height:40px">
                                <el-image :src="codeImg"   @click="clickImg" style="height:40px"/>
                            </span>
                        </div>
                        <div style=" margin-top:20px; text-align:left ">
                            <el-checkbox true-label="Y" false-label="N" v-model="form.read"  style=" margin-right:10px;"  ></el-checkbox>
                            <span style="font-size:10px;">{{fldObj['a_read']}}<el-link @click="userProtocol" :underline="false" style="color:#409EFF">《{{fldObj['a_service']}}》</el-link>&<el-link :underline="false" style="color:#409EFF" @click="userPriv">《{{fldObj['a_priv']}}》</el-link></span>     
                        </div> 
                                            
                        <el-button :disabled="form.read==='N'" :loading="loading" @click="normalLogin" type="primary"  icon="el-icon-s-custom" style=" margin-top:20px;width:460px;height:45px;" >
                            <span v-if="!loading">{{fldObj['a_login']}}</span>
                            <span v-else>{{fldObj['a_logining']}}</span>
                        </el-button>
                            
                        <div style="float:right;margin-top:15px;margin-bottom:20px;height:60px;">
 
                            <el-link @click="wxShow" :underline="false" >
                                <el-image src="/assets/authorize/wx-login.png"  style="height:35px;width:35px;margin-right:20px; "></el-image> 
                            </el-link>
                            <el-link @click="aliShow" :underline="false" style="margin-left:20px;margin-right:20px;">
                                <el-image fit="fill" src="/assets/authorize/alipay-login.png"  style="height:40px;width:40px; "></el-image> 
                            </el-link>
                            <i class="iconfont icon-wentizhaohui" style="color:#409eff"></i>
                            <a href="javascript:void(0)" @click="findPassword"> {{fldObj['a_foget']}} </a>
                            <Divider type="vertical" class="divcolor"/> 
                            <i class="iconfont icon-zhuce" style="color:#409eff"></i>
                            <a href="javascript:void(0)" @click="userRegister"> {{fldObj['a_register']}} </a>
                        </div> 
 
                    </div>
                </TabPane>
                <TabPane :label="fldObj['a_tab2']" name="msgvalidate" icon="ios-mail-outline">
                    <div style=" margin-top:8px; ">
                        <el-input :placeholder="form.telno" v-model="msgcode.phoneno" v-enter-number style="width:440px;margin-left:10px;vertical-align:center">
                            <el-select v-model="msgcode.area" slot="prepend" style="width:232px; " :placeholder="fldObj['a_select']"  :filterable="filter">
                                <el-row v-for="item in tabCol" :key='item.key' style="width:280px">
                                    <el-col :span="24">
                                        <el-option v-if="LANG==='EN'" :label="item.CRCYNM" :value="item.CODE" ></el-option> 
                                        <el-option v-else  :label="item.AREACODE" :value="item.CODE" ></el-option> 
                                    </el-col>
                                </el-row>
                            </el-select>                              
                        </el-input> 
                    </div>
                    <div style=" margin-top:20px;display:flex;justify-content:between ">
                        <el-input :placeholder="fldObj['a_code']" v-model="msgcode.validmsg" v-enter-number style="width:255px;margin-left:45px;"></el-input> 
                        <el-button v-show="sendAuthCode" type="primary" style="width:184px;margin-left:1px;" @click="getAuthCode">{{fldObj['a_check']}}</el-button>
                        <el-button disabled v-show="!sendAuthCode" type="primary" style="width:184px;margin-left:1px;" >{{auth_time}}{{fldObj['a_send']}}</el-button>
                    </div>  
                    <div style=" margin-top:20px; ">
                        
                        <el-checkbox true-label="Y" false-label="N" v-model="msgcode.read"  style="margin-left:15px;margin-right:10px;"  ></el-checkbox>
                        <span style="font-size:10px;">{{fldObj['a_read']}}<el-link :underline="false" style="color:#409EFF" @click="userProtocol">《{{fldObj['a_service']}}》</el-link>&<el-link :underline="false" style="color:#409EFF" @click="userPriv">《{{fldObj['a_priv']}}》</el-link></span>     
                    </div>  
                    <div style="display:flex;flex-direction:column">
                        <div style="text-align:center">
                            <el-button type="primary" :disabled="msgcode.read==='N'" style=" margin-top:20px;width:440px;margin-left:10px;height:40px;" icon="iconfont icon-duanxin1" @click="btnMsg">
                                <span style="font-size:14px;">{{fldObj['a_login']}}</span>
                            </el-button> 
                        </div>  
                        <div style="float:right;margin-top:25px; height:60px;margin-left:320px; ">
                            <i class="iconfont icon-zhuce" style="color:#409eff"></i>
                            <a href="javascript:void(0)" @click="userRegister"> {{fldObj['a_register']}} </a>
                        </div>   
                    </div>                   
                </TabPane>      
            </Tabs>  
            <!-- 提示框 -->
            <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
                <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                    <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">{{fldObj['a_prompt']}}</span>
                </div>
                <div style=" margin:15px 10px">
                    {{prompt}}
                </div>
                <div slot="footer">
                    <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">{{fldObj['a_sure']}}</Button>
                </div>
            </el-dialog>
            <Modal v-model="phoneModal"   scrollable :title="form.mobile_app"  
                    :styles="{top: '3px'}"  width="420px"  footer-hide>
                <div style=" text-align:center;vertical-align:center; ">
                    <img src="@/assets/kreco_phone.jpg"> 
                </div> 
                <div style=" margin-top:2px; text-align:center;vertical-align:center;font-weight:600;font-size:18px; ">
                    {{fldObj['a_wx']}}
                </div>              
            </Modal>   
        </div>
 
        <el-dialog :visible.sync="dialogVisible" width="600px" append-to-body :destroy-on-close="true" :show-close="false" :style="{'border':'1px solid blue'}">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{elTitle}}</span>
            </div>
            <div v-html="comment" style="height:400px; overflow-y:auto"> </div>
            <span slot="footer" :style="{'height':'40px' }" >
                <el-button size="small" type="primary" @click="dialogVisible = false">{{fldObj['a_next']}}</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="showWx" width="600px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false"  >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{fldObj['a_wxlogin']}}</span>
            </div>
            <iframe
                :src="wx_url"
                frameborder="0"
                width="100%"
                height="400px"
                scrolling="auto"
                >
            </iframe>
        </el-dialog>
    </div>
</template>

<script>
 import { Message } from 'iview' 
 import {getDate ,paramDataOrder} from '../../api/Select'
 
 import {getBaseData} from '../../api/user'
    export default {
        metaInfo() { 
        return {
                title: this.setForm['title'] , // set a title
                meta: [{             // set meta
                    name: 'keyWords',
                    content: this.setForm['keyword']
                },
                    {
                    name: 'description',
                    content: this.setForm['describe']
                    }],
                link: [{ // set link
                    rel: 'asstes',
                    href: this.setForm['link']
                }]
            } 
        },
        props: {
            v_headFlag:{
                type: Boolean,
                default:true
            },
            v_pid:{
                type: Boolean,
                default:false
            }
        },
        name: "Login",
        data(){
            return {
                promptWin:false,
                prompt:'',
                chklang:'',
                dialogVisible:false,
                comment:'',
                elTitle:'',
                sendAuthCode:true,/*布尔值，通过v-show控制显示 获取按钮 还是 倒计时  */
                auth_time: 0, /*倒计时 计数器*/
                loading:false,
                enpassword:'',
                validateKey:'',
                codeImg:'',
                foothide:false,
                wx_url:'https://open.weixin.qq.com/connect/qrconnect?appid=wxad557d574cd2196c&redirect_uri=http://www.ipskre.com/%23/loginwx&response_type=code&scope=snsapi_login&state=front'+'#wechat_redirect',
                ali_url:'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002174688229&redirect_uri=http%3A%2F%2Fwww.ipskre.com%2F%23%2Flogin_alipay&scope=auth_user&state=front&t='+getDate('year'),
                phonesrc:'@/assets/kreco_phone.jpg',
                filter:true,
                remchk:false,
                msgcode:{
                    phoneno:'',
                    area:'',
                    validmsg:'',
                    read:'N',
                },
                showWx:false,
                phoneModal:false,
                tabCol:[], //Select 数据集
                setForm:{
                    title:'',   //抬头
                    keyword:'',  //关键字
                    describe:'', //内容描述
                    link:'',     //链接地址
                },
                LANG:'',
                fldObj:{},
                pid:'',
                form:{
                    username:"",
                    password:"",
                    validate:'',
                    seltip:'',//请选择 
                    telno:'',//请输入手机号
                    teltip:'',//输入6位手机短信校验码
                    code:'',//获取校验码
                    loginName: '',//登录
                    loginName2: '',//登录中...
                    register: '',//注册
                    validtip:'',//请输入验证码
                    mobile_app:'',//下载手机客户端
                    forget_pwd:'', //找回密码
                    private:'',//隐私协议
                    help:'',//帮助
                    wxlogin:'',//微信登录
                    wxtip:'',//请使用微信扫描上面二维码
                    tabnm1:'', //账户密码登录
                    tabnm2:'',//短信验证码登录
                    usertip:'',//请输入用户名
                    pwdtip:'', //请输入密码
                    remember:'',  //记住我
                    read:'N',
                    
                }
            }
        },
        computed: {
          validateCompute:{
              get(){
                  return this.form.validate
              },
              set(val){
                  this.form.validate =val.toUpperCase() 
              }
          }  
        },
        mounted() {
            this.clickImg();  
            this.form.username =this.$cookies.get('v_telno')
            this.msgcode.phoneno =this.$cookies.get('v_telno')
            if  (this.$cookies.get('v_remember')==='Y' ){
                this.form.password =this.$cookies.get('v_pwd')
                this.remchk =true
            }
            this.msgcode.area ='305' //中国(+86)
            this.getField() //语言字体设置
            this.seoInit()
            if (this.v_pid){
                this.pid ='paycar'
            }else{
                this.pid =this.$route.query.form
            }
        },
        created () {
            
           this.LANG =this.$route.query.lang 
           //国家代号
           getBaseData(this.$store.state.nologinUrl, '','','','','v_sys_base'," and parentno in (select code from sys_base where parentno='238')").then(res=>{
                this.tabCol=res.data.result
            })
                                        
        },
        methods:{
            seoInit(){
                paramDataOrder('path','/frontLogin','v_SEOSET','').then((res)=>{ 
                    this.setForm['title'] =this.LANG=='CN'?res.data.result[0].TITLE:res.data.result[0].ETITLE
                    this.setForm['link'] =res.data.result[0].HREF
                    this.setForm['keyword'] =this.LANG=='CN'?res.data.result[0].KEYWORD:res.data.result[0].EKEYWORD
                    this.setForm['describe'] =this.LANG=='CN'?res.data.result[0].DESCRIBE:res.data.result[0].EDESCRIBE
                })
            },
            //界面字段语言设置
            getField(){
                let that =this
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'auth/login/getBaseData',
                    data: {p_key:'idseq', p_value:'55879',p_table:'V_BS_PAGEDTL'},
                }).then(res=>{ 
                    for(let k=0 ;k<res.data.result.length; k++){
                        that.fldObj[res.data.result[k]['SUBNO']] =that.LANG=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                    }
                    that.$forceUpdate()
                })        
            },
            aliShow(){
                window.location.href=this.ali_url
            },
            //用户协议
            userProtocol(){
                this.dialogVisible=true
                getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ','39085','lstseq','1','v_bs_frontdtl','').then(res=>{
                    this.elTitle= this.fldObj['a_platform']
                    this.comment =res.data.result[0].note
                })
            },
             //隐私协议
            userPriv(){
                this.dialogVisible=true
                getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ','39085','lstseq','2','v_bs_frontdtl','').then(res=>{
                    this.elTitle=this.fldObj['a_platform']
                    this.comment =res.data.result[0].note
                })
            },
            imgRoute(){
                let routeUrl = this.$router.resolve({
                    path: '/',
                    query: { param:'main',lang:this.LANG},
                });
                window.open(routeUrl.href, '_self');
            },
 
            //短信登录
            btnMsg() {
                let  that =this
                if (!this.msgcode.validmsg){
                    this.promptWin=true
                    this.prompt =this.fldObj['a_code']
                }else if (!this.msgcode.phoneno){
                    this.promptWin=true
                    this.prompt =this.fldObj['a_mobile']
                }else {
                    //短信验证码 10分钟有效 及合法性判断
                    this.$axios({
                        method:'post', 
                        url: this.$store.state.baseUrl+'auth/login/chkMessage' ,
                        data:{  //get这里应为params //请求参数
                            telno :this.msgcode.phoneno,
                            code :this.msgcode.validmsg,
                        },
                    }).then(res =>{
                        if (res.data.code === '200'){
                            that.$cookies.set('v_telno',this.msgcode.phoneno); //手机号码  
                            that.$cookies.set('v_idseq',res.data.idseq); //用户名称  
                            that.$cookies.set('v_pwd',this.form.password); //原密码
                            this.$axios({
                                method:'post',
                                url: url_+'auth/encodePwd' ,
                                data:{   password :this.form.password},
                            }).then(res=>{
                                that.$cookies.set('v_enpwd',res.data.message); //加密后的密码 
                            })
                            
                            that.$emit('child-ok',this.msgcode.phoneno); 
                            if (this.pid==='base'){
                                let routeUrl = this.$router.resolve({
                                    path: "/member/basic_info",
                                    query: {lang:this.LANG },
                                });
                                window.open(routeUrl.href, '_self'); 
                            }else if (this.pid==='doc'){
                                that.$router.replace('/docmgt/doc_updown'); 
                            }else if (this.pid==='prd'){
                                that.$router.replace('/prd_upload'); 
                            }else if (this.pid==='credit_qry'){
                                that.$router.replace('/credit_qry'); 
                            }else if (this.pid==='paycar'){
                                that.$emit('child-pay');  
                            } else{
                                let routeUrl = that.$router.resolve({
                                    path: '/',
                                    query: { param:'login',userno:this.msgcode.phoneno},
                                });
                            }
                            window.open(routeUrl.href, '_self');
                        }else{
                            if (res.data.code === '300'){
                                that.promptWin=true
                                that.prompt ="This mobile phone number is not bound to the login account, please bind after login"
                            }else if (res.data.code === '301'){
                                that.promptWin=true
                                that.prompt ="SMS verification code error, please check it"
                            }else if (res.data.code === '302'){
                                that.promptWin=true
                                that.prompt ="Mobile phone SMS verification code exceeds the validity period of 10 minutes, please get it again"
                            }else{
                                that.promptWin=true
                                that.prompt =res.data.result
                            }
                        }
                    }) 

                }
            },
            //获取验证码
            getAuthCode() {
                if (!this.msgcode.area){
                    this.promptWin=true
                    this.prompt =this.fldObj['a_area']
                }else if (!this.msgcode.phoneno){
                    this.promptWin=true
                    this.prompt =this.fldObj['a_mobile']
                }else {
                    console.log(this.msgcode.phoneno+":"+this.msgcode.area)
                    let area_ =this.msgcode.area==='305'?'china':'foreign'
                    if (this.msgcode.area!=='305'){
                        this.promptWin =true
                        this.prompt =this.fldObj['a_msgrmk']
                    }else{
                        //消息验证
                        this.$axios({
                            method:'post', 
                            url: this.$store.state.baseUrl+'auth/login/sendMessage' ,
                            data:{  //get这里应为params //请求参数
                                telno :this.msgcode.phoneno,
                                area :area_,
                                areano:this.msgcode.area
                            },
                        }).then(res =>{
                            if (res.data.code === 'OK'){
                                this.sendAuthCode = false;
                                this.auth_time = 60;
                                var auth_timetimer =  setInterval(()=>{
                                    this.auth_time--;
                                    if(this.auth_time<=0){
                                        this.sendAuthCode = true;
                                        clearInterval(auth_timetimer);
                                    }
                                }, 1000);
                            }else{      
                                this.promptWin=true
                                this.prompt =res.data.message
                            }
                        }) 
                    }
                }
            },
 
            //用户注册
            userRegister(){
                let routeUrl = this.$router.resolve({
                    path: '/register',
                    query: {lang:this.LANG },
                });
                window.open(routeUrl.href, '_self');
            },
            //找回密码
            findPassword(){
                //this.$router.replace('/get_forget');
                //this.$router.push({path:'/get_forget'});  //push 会在history留下记录，replace不会
                let routeUrl = this.$router.resolve({
                    path: '/get_forget',
                    query: {lang:this.LANG },
                });
                window.open(routeUrl.href, '_self');
            },
            //微信登录 
            wxShow(){
                //window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxad557d574cd2196c&redirect_uri=http://www.ipskre.com/%23/loginwx&response_type=code&scope=snsapi_login&state=front'+'#wechat_redirect'
                this.showWx =true
            },
            //手机登录
            phoneShow(){
                this.phoneModal =true
            },
            //点击图片重新生成
            clickImg(){
                //this.form.url =this.$store.state.baseUrl+'auth/login/getValidateImg?t='+Math.random();
                var vm =this
                let url_ =this.$store.state.baseUrl+'auth/login/getValidateImg?t='+Math.random();
                this.$axios({
                    method: 'get',
                    url:url_ ,
                    responseType: 'arraybuffer',
                }).then(res=>{ 
                    //获取后端接口定义的response header中的参数
                    vm.validateKey = res.headers.validatecode 
                    //console.log(vm.validateKey+"///"+JSON.stringify(res.headers))
                    //把二进制流转化为 base64 就不需要调用二次接口，解决获取的验证字符和生成图片不是同一接口问题
                    return 'data:image/jpeg;base64,' + btoa(
                        new Uint8Array(res.data)
                            .reduce((data, byte) => data + String.fromCharCode(byte), '')
                        );    
                }).then(data => {
                    this.codeImg = data;
                }).catch(ex => {
                    console.error(ex);
                });
            } ,
            //登录
            normalLogin(){
              if (!this.form.username){
                this.promptWin=true
                this.prompt = this.fldObj['a_notuser']
              }else if (!this.form.password){
                  this.promptWin=true
                  this.prompt =this.fldObj['a_notpwd']
              }else if (!this.form.validate){
                  this.promptWin=true
                  this.prompt = this.fldObj['a_notchk']
              } else {
                if (this.form.validate!==this.validateKey)   {
                    this.promptWin=true
                    this.prompt =this.fldObj['a_notok']
                }else {                
                    const that=this;
                    that.loading=true;
                    let url_ =this.$store.state.baseUrl;
                    // 密码加密
                    this.$axios({
                        method:'post',
                        url: url_+'auth/encodePwd' ,
                        data:{  //get这里应为params //请求参数
                            password :this.form.password
                        },
                        //withCredentials:true, //局部携带cookie
                        headers:{} //如果需要添加请求头可在这写
                    }).then(res => {  //res是返回结果
                        this.enpassword = res.data.message  //存数据
                        //登录验证
                        this.$axios({
                            method:'post',
                            url: url_+'auth/login' ,
                            data:{  //get这里应为params //请求参数
                                password :this.form.password,
                                username :this.form.username
                            },
                        }).then(res =>{
                            if (res.data.code === '100'){
                                setTimeout(()=>{     
                                    that.$cookies.set('v_telno',res.data.userno); //用户名称 
                                    that.$cookies.set('v_idseq',res.data.idseq); //用户名称   
                                    that.$cookies.set('v_pwd',this.form.password); //原密码    
                                    that.$cookies.set('v_enpwd',this.enpassword); //加密后的密码 
                                    that.$cookies.set('v_remember','Y');   
                                    that.$emit('child-ok',res.data.userno);    
                                    if (that.pid==='base')  {
                                        let routeUrl = this.$router.resolve({
                                            path: "/member/basic_info",
                                            query: {lang:this.LANG },
                                        });
                                        window.open(routeUrl.href, '_self'); 
                                    }else if (this.pid==='doc'){
                                        that.$router.replace('/docmgt/doc_updown'); 
                                    }else if (this.pid==='prd'){
                                        that.$router.replace('/prd_upload'); 
                                    }else if (this.pid==='credit_qry'){
                                        that.$router.replace('/credit_qry'); 
                                    }else if (this.pid==='paycar'){
                                        that.$emit('child-pay');  
                                    }else if (this.pid==='bom'){
                                        let routeUrl = this.$router.resolve({
                                            path: "/member/basic_info",
                                            query: {active:'/pomgt/bom_bill' },
                                        });
                                        window.open(routeUrl.href, '_blank'); 
                                    } else{                        
                                        let routeUrl = this.$router.resolve({
                                            path: '/',
                                            query: { param:'login',userno:res.data.userno},
                                        });
                                        this.$nextTick(()=>{
                                            window.open(routeUrl.href, '_self')
                                        })
                                        
                                     }
                                },1000)      
                            }else{
                                if (res.data.code === '200'){
                                    that.promptWin=true
                                    that.prompt ="wrong password,please check it"
                                }else if (res.data.code === '201'){
                                    that.promptWin=true
                                    that.prompt ="user does not exist,please check it"
                                }else{
                                    that.promptWin=true
                                    that.prompt ="the account is not exist,please register it"
                                }
                                that.loading=false 
                            }
                        })
                    }).catch(err => { //请求失败就会捕获报错信息
                        //err.response可拿到服务器返回的报错数据       
                    }) 
                    /* */
                  }
               }
            },
        }
    }
</script>
<style>
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    }
 
</style>
<style scoped lang="less">
 
.ms-title {
    width: 100%;
    line-height: 50px;
    height: 50px;
    font-size: 1.8em;
    font-weight: 700;
    color: #2b85e4;
    margin-top:15px;
}
.rowstyle {
    width: 520px;
    margin: 1px auto;
    border-radius: 5px;
    background: white;
    text-align:center;
    //margin-top:100px;
    border:1px solid #ccc;
}
.left{
    float:left
}
.right{
    float:right;
    margin-right:5px;
}
.ms-login {
    width: 1200px;
    margin: 1px auto;
    border-radius: 5px;
    background: white;//#f0f8ff;
} 
.ms-content {
    padding: 10px 30px;
}
.login-btn {
    height: 42px;
    display: flex;
    justify-content: space-around;
    margin-top:20px;
}
.login-btn button {

    height: 36px;
    margin-bottom: 3px;
}
 
.logo {
  width: auto;
  height: 50px;
  margin-bottom:10px;
}
.remember {
    width: 100%;
    line-height: 20px;
    float:left;
    font-size: 8px;
    color: #2b85e4;
    font-weight: 500; 
}
.divcolor{
    background-color: #5cadff;
}
 
 

 
</style>
